import { windowCheck } from 'utils/helpers';

import Head from 'components/globals/head';
import Link from 'components/link/link';

const GetMobile = () => {
  switch (true) {
    case windowCheck && /android/i.test(navigator.userAgent):
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.thisclicks.wiw';
      break;
    case windowCheck && /iPad|iPhone|iPod/i.test(navigator.userAgent):
      window.location.href =
        'https://apps.apple.com/us/app/when-i-work-employee-scheduling/id383126470';
      break;
  }

  return (
    <section className="section" data-section="getMobileLinks">
      <Head />
      <div
        className="container"
        style={ {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '30px',
          width: '100%',
        } }
      >
        <img
          src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
          alt="when I Work Logo"
          style={ { marginBottom: '20px' } }
        />
        <h1 style={ { fontSize: '1.5rem' } }>Install Now</h1>

        <Link to="https://apps.apple.com/us/app/when-i-work-employee-scheduling/id383126470">
          <img
            className="manager-app-download"
            src="https://marketing-assets.wheniwork-production.com/2019/12/06115559/download-on-the-app-store.png"
            alt="Apple app store download button"
            style={ { maxWidth: '145px' } }
          />
        </Link>
        <Link to="https://play.google.com/store/apps/details?id=com.thisclicks.wiw">
          <img
            className="manager-app-download"
            src="https://marketing-assets.wheniwork-production.com/2019/12/06115600/download-on-google-play-badge.png"
            alt="Google Play Store Download Button"
            style={ { maxWidth: '145px' } }
          />
        </Link>
      </div>
    </section>
  );
};

export default GetMobile;
