import { ReactNode } from 'react';
import { Link as GatsbyLink } from 'gatsby';

type LinkProps = {
  activeClassName?: string
  children: ReactNode
  className?: string
  href?: string
  key?: string | number,
  onClick?: () => void,
  partiallyActive?: boolean,
  rel?: string,
  tabIndex?: number,
  target?: string,
  title?: string,
  to?: string
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }:LinkProps) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby
  // will start with exactly one slash, and that anything else is external.
  const internal = /(^\/(?!\/)|^https?:\/\/wheniwork\.com)/;
  const gatsbySiteUrl = `${process.env.GATSBY_SITE_URL}`;
  const internalBlogLink = new RegExp(`^https?://${gatsbySiteUrl}`);

  if (to && internal.test(to)) {
    const modifiedInternal = to.replace(/^https?:\/\/wheniwork\.com/, '');

    return (
      <GatsbyLink
        to={ modifiedInternal }
        activeClassName={ activeClassName }
        partiallyActive={ partiallyActive }
        { ...other }
      >
        {children}
      </GatsbyLink>
    );
  } else if (to && internalBlogLink.test(to)) {

    const modifiedInternal = to
      //updated the following line because it all of a sudden started to create /blog/blog/internalurl
      .replace(internalBlogLink, '')
      .replace(/\/$/, '');

    return (
      <GatsbyLink
        to={ modifiedInternal }
        activeClassName={ activeClassName }
        partiallyActive={ partiallyActive }
        { ...other }
      >
        {children}
      </GatsbyLink>
    );
  } else {
    return (
      <a href={ to } { ...other } target="_blank" rel="noopener noreferrer">
        { children}
      </a>
    );
  }
};

export default Link;
